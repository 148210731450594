/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.hamburger').click(function() {
          $(this).toggleClass('is-active');
          $('body').toggleClass('openNav');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        var words = document.getElementsByClassName('word');
        var wordArray = [];
        var currentWord = 0;

        words[currentWord].style.opacity = 1;
        for (var i = 0; i < words.length; i++) {
          splitLetters(words[i]);
        }

        function changeWord() {
          var cw = wordArray[currentWord];
          var nw = currentWord == words.length - 1 ? wordArray[0] : wordArray[currentWord + 1];
          for (var i = 0; i < cw.length; i++) {
            animateLetterOut(cw, i);
          }

          for (var i = 0; i < nw.length; i++) {
            nw[i].className = 'letter behind';
            nw[0].parentElement.style.opacity = 1;
            animateLetterIn(nw, i);
          }

          currentWord = (currentWord == wordArray.length - 1) ? 0 : currentWord + 1;
        }

        function animateLetterOut(cw, i) {
          setTimeout(function() {
            cw[i].className = 'letter out';
          }, i * 80);
        }

        function animateLetterIn(nw, i) {
          setTimeout(function() {
            nw[i].className = 'letter in';
          }, 340 + (i * 80));
        }

        function splitLetters(word) {
          var content = word.innerHTML;
          word.innerHTML = '';
          var letters = [];
          for (var i = 0; i < content.length; i++) {
            var letter = document.createElement('span');
            letter.className = 'letter';
            letter.innerHTML = content.charAt(i);
            word.appendChild(letter);
            letters.push(letter);
          }

          wordArray.push(letters);
        }

        changeWord();
        setInterval(changeWord, 4000);
        $.googlePlaces = function(element, options) {

          var defaults = {
            placeId: 'ChIJdaE7BoF0j1QR-AMkZycqqRg' // placeId provided by google api documentation
              ,
            render: ['reviews'],
            min_rating: 0,
            max_rows: 0,
            rotateTime: false
          };

          var plugin = this;

          plugin.settings = {}

          var $element = $(element),
            element = element;

          plugin.init = function() {
            plugin.settings = $.extend({}, defaults, options);
            $element.html("<div id='map-plug'></div>"); // create a plug for google to load data into
            initialize_place(function(place) {
              plugin.place_data = place;
              // render specified sections
              if (plugin.settings.render.indexOf('reviews') > -1) {
                renderReviews(plugin.place_data.reviews);
                if (!!plugin.settings.rotateTime) {
                  initRotation();
                }
              }
            });
          }

          var initialize_place = function(c) {
            var map = new google.maps.Map(document.getElementById('map-plug'));

            var request = {
              placeId: plugin.settings.placeId
            };

            var service = new google.maps.places.PlacesService(map);

            service.getDetails(request, function(place, status) {
              if (status == google.maps.places.PlacesServiceStatus.OK) {
                c(place);
              }
            });
          }

          var sort_by_date = function(ray) {
            ray.sort(function(a, b) {
              var keyA = new Date(a.time),
                keyB = new Date(b.time);
              // Compare the 2 dates
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            return ray;
          }

          var filter_minimum_rating = function(reviews) {
            for (var i = reviews.length - 1; i >= 0; i--) {
              if (reviews[i].rating < plugin.settings.min_rating) {
                reviews.splice(i, 1);
              }
            }
            return reviews;
          }

          var renderReviews = function(reviews) {
            reviews = sort_by_date(reviews);
            reviews = filter_minimum_rating(reviews);
            var html = "";
            var row_count = (plugin.settings.max_rows > 0) ? plugin.settings.max_rows - 1 : reviews.length - 1;
            // make sure the row_count is not greater than available records
            row_count = (row_count > reviews.length - 1) ? reviews.length - 1 : row_count;
            for (var i = row_count; i >= 0; i--) {
              var stars = renderStars(reviews[i].rating);
              var date = convertTime(reviews[i].time);
              html = html + "<div class='review-item'>" + stars + "<p class='review-text'>" + reviews[i].text + "</p></div><div class='review-meta'><span class='review-author'>" + reviews[i].author_name + "</span><span class='review-date'>" + date + "</span></div>"
            };
            $element.append(html);
          }

          var initRotation = function() {
            var $reviewEls = $element.children('.review-item');
            var currentIdx = $reviewEls.length > 0 ? 0 : false;
            $reviewEls.hide();
            if (currentIdx !== false) {
              $($reviewEls[currentIdx]).show();
              setInterval(function() {
                if (++currentIdx >= $reviewEls.length) {
                  currentIdx = 0;
                }
                $reviewEls.hide();
                $($reviewEls[currentIdx]).fadeIn('slow');
              }, plugin.settings.rotateTime);
            }
          }

          var renderStars = function(rating) {
            var stars = "<div class='review-stars'><ul class='list-inline'>";

            // fill in gold stars
            for (var i = 0; i < rating; i++) {
              stars = stars + "<li class='list-inline-item'><i class='material-icons'>star</i></li>";
            };

            // fill in empty stars
            if (rating < 5) {
              for (var i = 0; i < (5 - rating); i++) {
                stars = stars + "<li><i class='material-icons'>star_border</i></li>";
              };
            }
            stars = stars + "</ul></div>";
            return stars;
          }

          var convertTime = function(UNIX_timestamp) {
            var a = new Date(UNIX_timestamp * 1000);
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var time = months[a.getMonth()] + ' ' + a.getDate() + ', ' + a.getFullYear();
            return time;
          }

          plugin.init();

        }

        $.fn.googlePlaces = function(options) {

          return this.each(function() {
            if (undefined == $(this).data('googlePlaces')) {
              var plugin = new $.googlePlaces(this, options);
              $(this).data('googlePlaces', plugin);
            }
          });

        }
        $("#google-reviews").googlePlaces({
            placeId: 'ChIJdaE7BoF0j1QR-AMkZycqqRg' //Find placeID @: https://developers.google.com/places/place-id
          , render: ['reviews']
          , min_rating: 1
          , max_rows:1
       });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'units': {
      init: function() {
        $( document ).ready(function() {
          var filterizd = $('.unit-container').filterizr({
             //options object
          });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
